/* global google */
/* eslint no-undef: "error" */

import themes from 'google_map_themes';

const googleMapsRWA = (function () {
  // https://en.wikipedia.org/wiki/Geographic_center_of_the_contiguous_United_States
  const US_CENTER = Object.freeze({ lat: 39.8283, lng: -98.5795 });
  const NATION_ZOOM_LEVEL = 4; // Why 4? At this view level (US Map view), this one looks good.
  const ADDR_ZOOM_LEVEL = 17; // Why 17? Tried different levels ... this one looks good for addresses.
  let map, marker, autocomplete;

  // Google Maps unfortunately don't provide an easy way of extracting address information, so we use
  // this function to help us out and parse the 'address_components' type:
  // https://developers.google.com/maps/documentation/javascript/reference/geocoder#GeocoderAddressComponent
  const parseAddressComponents = function (addressComponents) {
    const address = {
      streetNumber: '',
      zip: '',
      streetName: '',
      region: '',
      city: '',
      country: ''
    };

    addressComponents.forEach((component) => {
      const addressType = component.types[0];

      switch (addressType) {
        case 'street_number':
          address.streetNumber = component.long_name;
          break;
        case 'route':
          address.streetName = component.short_name;
          break;
        case 'locality':
          address.city = component.long_name;
          break;
        case 'administrative_area_level_1':
          address.state = component.short_name;
          break;
        case 'postal_code':
          address.zip = component.long_name;
          break;
        case 'country':
          address.country = component.long_name;
          break;
      }
    });

    return address;
  };

  const updateAddressFormFields = function (address) {
    document.getElementById('location_street_address').value = `${address.streetNumber} ${address.streetName}`;
    document.getElementById('location_city').value = address.city;
    document.getElementById('location_building').value = '';
    document.getElementById('location_state').value = address.state;
    document.getElementById('location_zip').value = address.zip;
  };

  const updateAddress = function () {
    marker.setVisible(false);

    const place = autocomplete.getPlace();
    if (!place.geometry) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      window.alert("No details available for input: '" + place.name + "'");
      return;
    }

    // If the place has a geometry, then present it on a map.
    // The geometry is basically the object that has the latitude and longitude information needed to
    // render it on the map.
    // For more details: https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceGeometry
    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else {
      map.setCenter(place.geometry.location);
      map.setZoom(ADDR_ZOOM_LEVEL);
    }
    marker.setPosition(place.geometry.location);
    marker.setVisible(true);

    if (place.address_components) {
      const address = parseAddressComponents(place.address_components);
      updateAddressFormFields(address);
    }
  };

  const initMap = function (mapElem) {
    let mapCenter = US_CENTER;
    let mapZoomLevel = NATION_ZOOM_LEVEL;

    const lat = mapElem.dataset.lat;
    const lng = mapElem.dataset.lng;

    if (lat && lng) {
      mapCenter = new google.maps.LatLng(lat, lng);
      mapZoomLevel = ADDR_ZOOM_LEVEL;
    }

    map = new google.maps.Map(mapElem, {
      center: mapCenter,
      styles: themes.silver,
      zoom: mapZoomLevel,
      disableDefaultUI: true,
      zoomControl: true,
      scaleControl: true
    });

    const inputElem = document.getElementById('gmaps-address-input');
    if (inputElem) {
      // First let's make sure that we don't accidently submit the form when hitting enter on the
      // autocomplete input element since we want the user to explicitly click on the
      // 'Update Location' button to confirm they want to change the address
      const RETURN_KEY = 13;
      inputElem.addEventListener('keydown', (e) => {
        if (e.keyCode === RETURN_KEY) {
          e.preventDefault();
          return false;
        }
      });

      // Embed our address input element inside and on top of our Google map
      map.controls[google.maps.ControlPosition.TOP_RIGHT].push(inputElem);

      // Connnects/wires the autocomplete service to our input field
      autocomplete = new google.maps.places.Autocomplete(inputElem);

      // Bind the map's bounds (viewport) property to the autocomplete object,
      // so that the autocomplete requests use the current map bounds for the
      // bounds option in the request.
      autocomplete.bindTo('bounds', map);

      // Set the data fields to return when the user selects a place.
      autocomplete.setFields(['address_components', 'geometry']);

      autocomplete.addListener('place_changed', updateAddress);
    }

    marker = new google.maps.Marker({
      map: map,
      animation: google.maps.Animation.DROP,
      position: mapCenter
    });
  };

  const init = function () {
    const mapElem = document.getElementById('gmaps-map');
    if (mapElem) initMap(mapElem);
  };

  return {
    init: init
  };
})();

$(document).on('turbolinks:load', googleMapsRWA.init);
